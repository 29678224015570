/* Sidebar */
.sidebar {
  display: flex;
  position: sticky;
  top: 0;
  height: 100VH;
  flex-direction: column; /* Arrange items vertically */
  width: 250px; /* Default width */
  background-color: #ffffff;
  color: #080250; /* Dark blue text color */
  transition: transform 0.3s ease, width 0.3s ease;
  padding: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Light shadow */
  z-index: 999; /* Ensure it's on top of other elements */
  overflow: hidden; /* Prevent scrolling on the entire sidebar */
}

/* Collapsed Sidebar */
.sidebar.collapsed {
  width: 80px; /* Narrow width when collapsed */
}

/* Main Menu */
.main-menu {
  flex-grow: 1; /* Take up remaining vertical space */
  overflow-y: auto; /* Make the menu scrollable */
  padding: 0;
  margin: 0;
  max-height:80%; /* Restrict max height to leave space for profile */
}

.main-menu::-webkit-scrollbar{
  display: none;
}

.main-menu ul {
  list-style: none; /* Remove default list styles */
  padding: 0;
  margin: 0;
}

/* Menu Item */
.menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: #080250; /* Dark blue text color */
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 15px;
}

/* Hide Text When Collapsed */
.sidebar.collapsed .menu-item span {
  display: none; /* Hide text in collapsed state */
}

/* Menu Item Icon */
.menu-item i {
  margin-right: 15px;
  font-size: 1.5rem;
}

/* Hover Effect for Menu Item */
.menu-item:hover {
  background-color: #080250; /* Blue background on hover */
  color: white;
}

.menu-item:hover i {
  color: white; /* White icon color on hover */
}

/* Active Menu Item */
.active {
  font-weight: bold;
  background-color: #080250; /* Dark blue background for active item */
  color: white;
}

/* Profile Section */
.profile-section {
  height: 10%;
  background-color: #ffffff; /* Light background color for contrast */
  text-align: center;
  margin-bottom: 10px;
}

/* Collapsed Profile Section */
.sidebar.collapsed .profile-section span {
  display: none; /* Hide text when sidebar is collapsed */
}

.profile-section img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-section span {
  display: block;
  font-size: 14px;
  color: #080250;
  margin-top: 5px;
}
