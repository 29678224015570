.task-manager {
    padding: 20px;
    background: rgba(255, 255, 255, 0.15); /* Semi-transparent background for glass effect */
    border-radius: 15px;
    backdrop-filter: blur(10px); /* Blurring background for glassmorphism */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    color: #e0e0e0;
    height: 100%;
    font-family: 'Roboto', sans-serif; /* Modern font */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Border for glassmorphism */
}

h2, h3 {
    color: #1f8ef1; /* Futuristic blue for headings */
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.assign-task-button {
    background: linear-gradient(135deg, #1f8ef1, #5f72ff); /* Gradient button */
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.assign-task-button:hover {
    transform: translateY(-3px); /* Lift effect on hover */
    box-shadow: 0 8px 15px rgba(31, 142, 241, 0.4); /* Glowing shadow on hover */
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85); /* Darker background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: rgba(255, 255, 255, 0.2); /* Transparent background for glassmorphism */
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 32px rgba(31, 142, 241, 0.3);
    position: relative;
    width: 450px; /* Width of the modal */
    color: #e0e0e0;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.close {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    color: #5f72ff; /* Vibrant blue for close button */
    font-size: 1.2em;
    transition: color 0.3s ease;
}

.close:hover {
    color: #1f8ef1; /* Color change on hover */
}

.bulk-action-btn{
    color: #3d3d3d;
    margin-top: 15px;
}

.bulk-action-btn select{
    cursor: pointer;
    border: 1px solid rgb(211, 211, 211);
    padding: 15px 10px;
    border-radius: 8px;
    margin-right: 5px;
}

.bulk-action-btn select:focus{
    border: 1px solid gray;
}


.modal-content input,
.modal-content textarea,
.modal-content select {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    background: rgba(255, 255, 255, 0.993); /* Semi-transparent background for inputs */
    border: 1px solid #1f8ef1; /* Futuristic blue border */
    border-radius: 8px;
    color: #3d3d3d;
    font-size: 1em;
    transition: border-color 0.3s ease;
}

.modal-content input:focus,
.modal-content textarea:focus,
.modal-content select:focus {
    border-color: #5f72ff; /* Bright border on focus */
}

.task-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    backdrop-filter: blur(6px); /* Subtle blur for glass effect */
    overflow: hidden;
    border-radius: 10px;
}

.task-table th,
.task-table td {
    padding: 12px;
    font-size: 0.9em;
    text-align: center;
    color: #2c2c2c;
    background: rgba(0, 0, 0, 0.15); /* Light transparent background */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Border color for futuristic look */
}

.task-table th {
    background: linear-gradient(135deg, #1f8ef1, #5f72ff); /* Gradient header */
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
}

.task-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.05); /* Light blue for even rows */
}

.task-table tr:hover {
    background-color: rgba(95, 114, 255, 0.1); /* Light glow on hover */
    transition: background-color 0.2s ease;
}

.task-table select {
    padding: 5px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #5f72ff;
    color: #494949;
    font-weight: 500;
}
