.contact-details {
    padding: 20px;
}

.phone-section,
.email-section {
    margin-bottom: 20px;
}

.input-group {
    display: flex;               /* Enable flexbox */
    flex-wrap: wrap;            /* Allow wrapping of inputs */
    gap: 15px;                  /* Space between inputs */
}

.input-container {
    flex: 1 1 calc(33.33% - 10px); /* Flex basis set to allow 3 items per row */
    min-width: 200px;           /* Set a minimum width to maintain layout */
}

.input-field {
    width: 100%;                /* Full width of input area */
    padding: 8px;               /* Padding inside the input */
    border: 1px solid #ccc;     /* Border for input */
    border-radius: 4px;         /* Rounded corners */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.input-field:focus {
    border-color: #007BFF;      /* Change border color on focus */
    outline: none;              /* Remove default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional shadow for emphasis */
}

.input-field:hover {
    border-color: #007BFF;      /* Change border color on hover */
}

.input-label {
    display: block;              /* Label occupies full width */
    margin-bottom: 5px;         /* Space between label and input */
}

.permanent-address-checkbox {
    display: flex;               /* Flex container for checkbox */
    align-items: center;        /* Center align items vertically */
    margin-top: 10px;           /* Space above the checkbox section */
}

.permanent-checkbox {
    margin-right: 10px;         /* Space between checkbox and label */
}

/* Responsive Design */
@media (max-width: 600px) {
    .input-container {
        flex: 1 1 100%; /* Full width on small screens */
    }
}

/* Optional Checkbox Customization */
.permanent-checkbox {
    appearance: none;           /* Remove default styling */
    width: 20px;               /* Set custom width */
    height: 20px;              /* Set custom height */
    border: 2px solid #ccc;    /* Border for checkbox */
    border-radius: 3px;        /* Rounded corners */
    outline: none;              /* Remove outline */
    cursor: pointer;            /* Change cursor to pointer */
    position: relative;         /* Position relative for custom checkmark */
}

.permanent-checkbox:checked {
    background-color: #007BFF; /* Background color when checked */
    border: 2px solid #007BFF; /* Change border color when checked */
}

.permanent-checkbox:checked::after {
    content: '';                /* Create a custom checkmark */
    position: absolute;         /* Position the checkmark */
    left: 5px;                 /* Position it inside the checkbox */
    top: 9px;                  /* Adjust position */
    width: 8px;                /* Width of the checkmark */
    height: 2px;               /* Height of the checkmark */
    border: solid white;       /* White checkmark */
    border-width: 0 0 2px 2px; /* Create checkmark shape */
    transform: rotate(45deg);  /* Rotate to form a checkmark */
}
