/* Reports.css */

.reports-container {
  padding: 20px;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #383838;
}

.charts-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal-width columns for the first row */
  grid-template-rows: auto auto auto; /* Three rows */
  gap: 20px; /* Space between the grid items */
  color: black;
}

.chart-container-1,
.chart-container-2,
.chart-container-3,
.chart-container-4,
.chart-container-5,
.chart-container-6 {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-container-1 {
  grid-column: 1 / 2; /* First column, first row */
  grid-row: 1 / 2; /* First row */
}

.chart-container-2 {
  grid-column: 2 / 3; /* Second column, first row */
  grid-row: 1 / 2; /* First row */
}

.chart-container-3 {
  grid-column: 3 / 4; /* Third column, first row */
  grid-row: 1 / 2; /* First row */
}

/* Adjusted chart-container-4 and chart-container-5 to span equally */
.chart-container-4 {
  grid-column: 1 / 2; /* First column */
  grid-row: 2 / 3; /* Second row */
}

.chart-container-5 {
  grid-column: 2 / 4; /* Span the remaining two columns */
  grid-row: 2 / 3; /* Second row */
}

.chart-container-6 {
  grid-column: 1 / 4; /* Span all three columns */
  grid-row: 3 / 4; /* Third row */
}

.chart-title {
  margin-top: 20px;
  font-size: 1.2rem;
  text-align: center;
  color: #242424;
}

/* Ensure the charts are responsive and fit the grid items */
.chart-container-1 canvas,
.chart-container-2 canvas,
.chart-container-3 canvas,
.chart-container-4 canvas,
.chart-container-5 canvas,
.chart-container-6 canvas {
  max-width: 100%;
  height: auto;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .charts-wrapper {
    grid-template-columns: 1fr; /* One column layout for small screens */
    grid-template-rows: auto; /* Allow rows to adjust automatically */
  }

  .chart-container-1,
  .chart-container-2,
  .chart-container-3,
  .chart-container-4,
  .chart-container-5,
  .chart-container-6 {
    grid-column: 1 / -1; /* Each container takes full width */
  }
}

/* Hover effect for interactivity */
.chart-container-1:hover,
.chart-container-2:hover,
.chart-container-3:hover,
.chart-container-4:hover,
.chart-container-5:hover,
.chart-container-6:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
  transition: all 0.3s ease;
}

.centered-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 1.5rem;
  color: #555;
  text-align: center;
}