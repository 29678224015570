/* Dashboard Wrapper */
.dashboard-wrap {
    padding: 20px;
}

/* Dashboard Grid */
.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

/* Dashboard Grid Item */
.db-grid-item {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Notifications Wrapper */
.notification-wrap {
    padding: 0;
}

/* Notifications */
.notifications {
    max-height: 200px;
    overflow-y: auto;
    padding: 20px;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.notifications::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
    scroll-behavior: smooth;
}

/* Single Notification */
.notification {
    background: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.04);
}

/* Notification Types */
.notification.birthday {
    border-left: 5px solid #ffcc00; /* Yellow for birthdays */
}

.notification.work-anniversary {
    border-left: 5px solid #00ccff; /* Blue for work anniversaries */
}

/* Toggle Button */
.toggle-btn {
    background: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.toggle-btn:hover {
    background: #0056b3; /* Darker blue on hover */
}

/* User Profiles Grid */
.user-profiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
}

/* Profile Card */
.profile-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Profile Information */
.profile-info {
    margin-top: 15px;
}

.profile-info h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.profile-info p {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
}

/* Profile Avatar */
.profile-card Avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    border-radius: 50%;
    object-fit: cover;
}

/* Task Section */
.tasks-section {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .tasks-section h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .tasks-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Task Card */
  .task-card {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .task-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }
  
  /* Task Header */
  .task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .task-header h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .task-priority {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
    background: #007bff; /* Default priority color */
  }
  
  .task-priority[data-priority="Low"] {
    background: #8df78d;
  }
  
  .task-priority[data-priority="Medium"] {
    background: #fac37d;
  }
  
  .task-priority[data-priority="High"] {
    background: #fc8781;
  }
  
  .task-priority[data-priority="Critical"] {
    background: #e6e6ff;
  }
  
  /* Task Description */
  .task-description {
    font-size: 14px;
    color: #555;
    margin: 10px 0;
  }
  
  /* Task Meta */
  .task-meta {
    font-size: 12px;
    color: #777;
    margin-bottom: 10px;
  }
  
  /* Task Actions */
  .task-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .task-actions label {
    font-size: 14px;
    color: #333;
  }
  
  .task-actions select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
    cursor: pointer;
    background: #fff;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .task-actions select:hover {
    border-color: #007bff;
  }
  
  /* No Tasks */
  .no-tasks {
    padding: 20px;
    text-align: center;
    background: #f7f7f7;
    border-radius: 8px;
    color: #666;
    font-size: 14px;
  }
  
  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .confirmation-dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }
  
  .confirmation-buttons button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirmation-buttons button:first-child {
    background-color: #4caf50;
    color: white;
  }
  
  .confirmation-buttons button:last-child {
    background-color: #f44336;
    color: white;
  }
  