.profile-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.profile-data {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.profile-img {
    margin-bottom: 20px;
}

.user-data {
    width: 100%;
    text-align: center;
}

.profile-header .user-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.profile-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.user-info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.user-label {
    font-weight: bold;
    color: #555;
}

.user-value {
    color: #777;
}

.close-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-button:hover {
    background-color: #d32f2f;
}
