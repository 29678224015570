.personal-details-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .personal-details-container {
    width: 100%;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: #3f3d3d; /* White text color */
  }
  
  .personal-details-title {
    font-size: 24px;
    font-weight: 700;
    color: #0f1172; /* Green color for the title */
    margin-bottom: 10px;
  }
  
  .divider {
    height: 0.5px;
    border: 1px solid;
    margin: 15px 0;
  }
  
  .input-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .input-group {
    flex: 1 1 30%;
    min-width: 200px;
  }
  
  .input-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #2c2c2c; /* Light blue-gray for labels */
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .input-field:focus {
    border-color: #4caf50; /* Green focus border */
  }
  
  .gender-options {
    display: flex;
    gap: 10px;
  }
  
  .gender-option input {
    margin-top: 0;
  }
  
  .gender-option label {
    font-size: 14px;
    color: #363636; /* Light blue-gray for gender labels */
  }
  
  @media screen and (max-width: 768px) {
    .input-group {
      flex: 1 1 100%;
    }
  }
  