/* addemployee.css */

:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --text-color: #343a40;
  --border-radius: 8px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.add-employee-wrap {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.page-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  display: inline-block;
  padding-bottom: 5px;
}

.basic-info,
.address-section {
  margin-bottom: 30px;
}

.basic-info {
  display: flex;
  gap: 30px;
}

.employee-img {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.employee-img img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--primary-color);
}

.employee-img button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
}

.employee-img button:hover {
  background-color: #0056b3;
}

.employee-form {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.employee-form .MuiTextField-root {
  width: 100%;
}

.address-section h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--text-color);
  border-left: 5px solid var(--primary-color);
  padding-left: 10px;
}

.address-section .MuiTextField-root {
  width: calc(50% - 10px);
  margin-bottom: 15px;
}

.address-section .MuiTextField-root:nth-child(even) {
  margin-right: 0;
}

.address-section .MuiTextField-root:nth-child(odd) {
  margin-right: 10px;
}

button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 30px auto 0;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .basic-info {
    flex-direction: column;
  }

  .employee-form {
    grid-template-columns: 1fr;
  }

  .address-section .MuiTextField-root {
    width: 100%;
    margin-right: 0;
  }
}
