
h1 {
  text-align: center;
  color: #007bff;
  font-size: 2rem;
  margin-bottom: 30px;
}

/* Filters Section */
.filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters div {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
  color: #555;
}

input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.1);
}

input[type="text"]:focus,
input[type="date"]:focus {
  border-color: #007bff;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}

/* Calendar Custom Styling */
.react-calendar__tile {
  position: relative;
}

.react-calendar__tile.absent {
  background-color: red !important;
  color: white !important;
}

.react-calendar__tile.half-day {
  background-color: orange !important;
  color: white !important;
}

.react-calendar__tile.weekend {
  background-color: blue !important;
  color: white !important;
}

.react-calendar__tile.specialSaturday {
  background-color: blue !important;
  color: white !important;
}

/* Table Styling */
table {
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f1f1f1;
  color: #007bff;
  font-weight: bold;
}

tr:hover {
  background-color: #f9f9f9;
}

td {
  color: #555;
}
