.suggestions-dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.add-project-popup {
  background: rgba(88, 88, 88, 0);
  /* backdrop-filter: blur(5px); */
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 80%;
  overflow: auto;
}

.add-project-popup::-webkit-scrollbar{
  display: none;
}

.add-project{
  padding: 20px;
}

.text-field {
  width: 200px !important;
}

.select-field{
  width: 200px;
}

.projecttracker-wrap {
  position: relative;
}

.comment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.comment-popup{
  background: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.comment-box{
  background-color: rgba(10, 10, 10, 0.24);
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comment-preview-box{
  border-radius: 8px;
  display: flex;
}

.button-box{
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.button-box > .submit-btn:nth-child(1){
  background-color: rgb(57, 39, 155);
  color: white;
  font-weight: 600;
}
.button-box > .submit-btn:nth-child(2){
  background-color: rgb(235, 62, 62);
  color: white;
  font-weight: 600;

}

.comment-buttons{
  margin-top: 5px;
  display: flex;
  width: 50%;
  justify-content: space-around;
}

.comment-input {
  width: 500px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.comment-btn, .submit-btn{
  width: 150px;
  height: 50px;
}

.submit-project-btn{
  width: 150px;
  height: 50px;
  background-color: rgb(44, 167, 44) !important;
  color: white !important;
}
.cancle-project-btn{
  width: 150px;
  height: 50px;
  background-color: rgb(224, 61, 61) !important;
  color: white !important;
}

.comment-btn{
  background-color: rgb(34, 58, 138) !important;
  color: white !important;
}

.comment-preview {
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
  width: 200px;
  overflow: auto;
  cursor: pointer;
  cursor: grab;
}

.column-filter{
  display: flex;
  flex-direction: column;
}

.edit-btn{
  margin: 5px auto;
}

.comment-preview{
  width: 100%;
  height: 100px;
  border: 1px solid #C4C4C4;
  color: gray;
  padding: 5px 10px;
}
