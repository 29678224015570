.profile-box {
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #F6F5FB;
  /* background-color: white; */
  padding: 30px;
}

hr{
  margin: 10px 0;
}

.profile-menu {
  /* border: 1px solid red;  */
  border-right: none;
  color: white;
  width: 20%;
  background-color: rgb(46, 15, 131);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
}

.profile-pic{
  background-color: white;
  margin: 0  auto;
  object-fit: contain;
}

.designation{
  font-size: 0.8rem;
  margin-top: 10px;
}

.designation p{
  margin-bottom: 10px;
}

.designation p span{
  font-size: 0.8rem;
  font-weight: 500;
}

.profile-links{
  margin-top: 20px;
  padding-left: 20px;
}

.profile-links li{
  margin-bottom: 10px;
}

.profile-details {
  /* border: 1px solid red; */
  border-left: none;
  padding: 20px;
  width: 80%;
  background-color: rgb(255, 255, 255);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}


.perdonal-details span{
  font-size: 1rem;
  font-weight: 500;
}

.profileName span{
  font-weight: 400;
  font-size: 0.8rem;
}

.dataInput {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 20px;
margin-top: 20px;
margin-bottom: 30px;
/* background-color: antiquewhite; */
}

.inputarea {
  position: relative;
  height: 40px;
  /* background-color: blue; */
  line-height: 40px;
}

.inputarea input {
  position: absolute;
  width: 100%;
  outline: none;
  font-size: 0.9rem;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(211, 211, 211);
  background-color: transparent;
  transition: 0.1s ease;
  z-index: 201;
}

.inputarea select {
  position: absolute;
  width: 100%;
  outline: none;
  font-size: 0.9rem;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(211, 211, 211);
  background-color: transparent;
  transition: 0.1s ease;
  z-index: 201;
}

.input-title {
  position: absolute;
  font-size: 1rem;
  color: rgb(34, 34, 136);
  padding: 0 20px;
  margin: 0 25px;
  background-color: #ffffff;
  transition: 0.2s ease;
}

.inputarea input:focus,
.inputarea input:valid,
.inputarea input:active {
  color: rgb(109, 109, 109);
  border: 1px solid rgb(109, 109, 109);
}

.inputarea input:focus + .input-title,
.inputarea input:valid + .input-title,
.inputarea input:active + .input-title {
  color: rgb(109, 109, 109);
  height: 30px;
  line-height: 30px;
  transform: translate(-25px, -16px) scale(0.7);
  padding: 0 12px;
  z-index: 201;
}

.inputarea select:focus,
.inputarea select:valid,
.inputarea select:active {
  color: rgb(109, 109, 109);
  border: 1px solid rgb(109, 109, 109);
}

.inputarea select:focus + .input-title,
.inputarea select:valid + .input-title,
.inputarea select:active + .input-title {
  color: rgb(109, 109, 109);
  height: 30px;
  line-height: 30px;
  transform: translate(-25px, -16px) scale(0.7);
  padding: 0 12px;
  z-index: 201;
}

.input-title-gender{
  font-size: 0.7rem;
  transform: translateX(25px)
  translateY(-10PX);
}

.options , .gender-selection{
  display: flex;
  justify-content: space-evenly;
}

.gender-selection label{
  margin-left: 20px;
  margin-top: -8px;
  font-size: 0.8rem;
}

.gender-selection input{
  font-size: 0.4rem;
  margin-top: -8px;
}

.save-data{
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.save-data button{
  background-color: rgb(23, 23, 112);
  color: white ;
  padding: 10px 30px;
  font-weight: 500;
  border-radius: 10px;
  margin-left: 20px;
}

