.show-project-wrap{
  width: 100%;
  padding: 20px;
}

.filters-content {
  max-height: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 300px);
  width: 100%;
  background: linear-gradient(135deg, #ffffff, #e0e3e8);
  border-radius: 10px;
  padding: 0 10px;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.filters-content.open {
  max-height: 400px;
  padding: 20px;
}

.top-buttons {
  display: flex;
  align-items: center;
  padding: 40px 20px 20px 20px;
  width: 100%;
}

.import-export-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
}

.import-export-box > button {
  background: linear-gradient(145deg, #1033ce, #1f4690);
  color: #fff;
  font-weight: 600;
  padding: 15px 25px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, background 0.3s;
}

/* General button styles */
button.MuiButton-containedSecondary {
  background-color: #f50057; /* Default secondary color */
  color: white;
  transition: opacity 0.3s ease;
}

button.MuiButton-containedSecondary:hover {
  background-color: #c51162; /* Darker shade for hover */
}

/* Disabled button styles */
button.MuiButton-containedSecondary:disabled {
  background-color: #bdbdbd; /* Gray for disabled state */
  color: #9e9e9e; /* Lighter gray for text */
  opacity: 0.6; /* Faded effect */
  cursor: not-allowed; /* Show a not-allowed cursor */
}


.import-export-box > button:hover {
  background: linear-gradient(145deg, #1f4690, #1033ce);
  transform: scale(1.05);
}

.project-table {
  width: 100%;
  padding: 20px;
  display: flex;
  /* margin-bottom: -30px; */
}

/* Add Project Button */
.add-project-btn {
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.add-project-btn span {
  background: linear-gradient(145deg, #1033ce, #1f4690);
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  text-transform: uppercase;
}

.add-project-btn:hover span {
  background: linear-gradient(145deg, #1f4690, #1033ce);
}

/* Popup Styles */
.add-project-popup,
.import-export-popup {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(10, 10, 10, 0.75);
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.import-export-filter-popup {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #007bff;
}

.column-filter-section {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.input-filters {
  display: grid;
  grid-template-columns: repeat(4, 300px);
  gap: 15px;
  margin-top: 15px;
}

.input-filters input[type="text"] {
  padding: 10px;
  border: 1px solid #1f4690;
  border-radius: 6px;
  background: #f5f7fc;
}

.project-grid {
  padding: 20px;
}

.MuiPaper-root {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.MuiTableCell-head {
  background: linear-gradient(135deg, #1033ce, #182d8d);
  color: #ffffff !important;
  font-weight: 700;
}

.check-box {
  min-width: 40px !important;
}

.table-cell {
  min-width: 200px;
  min-height: 50px;
  width: 300px;
  max-width: 100%;
  border: 1px solid #d3d9e5;
  transition: background-color 0.3s;
}

.MuiTableRow-root:nth-child(odd) {
  background-color: rgba(30, 58, 138, 0.05);
}

.MuiTableRow-root:nth-child(even) {
  background-color: rgba(30, 58, 138, 0.1);
}

.MuiTableRow-root:hover {
  background: rgba(16, 51, 206, 0.1);
}

.comment-text {
  display: inline-block;
  max-width: 200px; /* Adjust this as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
