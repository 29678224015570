/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  /* max-width: 600px; */
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for a polished look */
}

.popup-content::-webkit-scrollbar{
  display: none;
}

/* User List */
/* User List */
.user-list {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 10px; /* Add spacing between items */
  overflow-y: auto;
}

.user-list::-webkit-scrollbar{
  display: none;
}


/* Employee List Item */
.employee-list-item {
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  color: #080250; /* Dark blue text */
  background-color: #f9f9ff; /* Light background for employee items */
  flex: 1 1 calc(50% - 20px); /* Flexible items, each taking ~33% of the container */
  box-sizing: border-box; /* Include padding and border in width */
}


.employee-list-item input {
  margin-right: 10px;
}

/* Buttons */
.save-permission-btn {
  padding: 10px 20px;
  color: white;
  background-color: #080250; /* Consistent dark blue */
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-edit {
  padding: 10px 20px;
  color: white;
  background-color: #e45050; /* Red for cancel */
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover Effects for Buttons */
.save-permission-btn:hover {
  background-color: #060244; /* Slightly darker blue on hover */
}

.cancel-edit:hover {
  background-color: #c03636; /* Darker red on hover */
}

/* Permissions Container */
.permissions-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Permissions Sections */
.permissions-sections {
  display: flex;
  justify-content: space-between;
}

.permission-section {
  width: 48%;
}

/* Lists */
.user-list,
.columns-list {
  list-style: none;
  padding: 0;
}

/* Buttons Container */
.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}


/* Columns List */
.columns-list {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 10px; /* Add spacing between items */
  padding: 0;
  list-style: none; /* Remove default list styling */
}

/* Column List Item */
.columns-list li {
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  color: #080250; /* Dark blue text */
  background-color: #f9f9ff; /* Light background for column items */
  flex: 1 1 calc(33.33% - 20px); /* Flexible items, each taking ~33% of row width */
  /* text-align: center; */
}

.column-check-box input{
margin-right: 10px;
}