.profile-dropdown {
  position: absolute;
  bottom: 20px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  background-color: rgb(24, 14, 80);
  border-radius: 50px;
  position: relative;
}

.profile-header.collapsed {
  cursor: default; /* Prevent interaction in collapsed mode */
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown-triangle {
  width: 0;
  height: 0;
  cursor: pointer;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(24, 14, 80);
  margin-left: 10px;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  background-color: white;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.profile-header.collapsed .dropdown-triangle {
  display: none; /* Hide dropdown arrow in collapsed mode */
}
