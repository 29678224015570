/* General Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --text-color: #343a40;
  --background-color: #f9f9f9;
  --btn-hover-color: #0056b3;
  --border-radius: 8px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Base Styling */
body {
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  margin: 0;
  background-color: var(--background-color);
}

.admin-panel {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* Filter Section */
.admin-filters {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.admin-filters input,
.admin-filters select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  flex: 1;
}

.filter-btn-reset,
.filter-btn-search {
  padding: 10px 16px;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-btn-reset:hover,
.filter-btn-search:hover {
  background-color: var(--btn-hover-color);
}

/* Table Section */
.admin-table-wrapper {
  margin-top: 20px;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.admin-table th,
.admin-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.admin-table th {
  background-color: var(--primary-color);
  color: #fff;
}

.admin-btn-edit,
.admin-btn-delete {
  padding: 8px 8px;
  border: none;
  border-radius: var(--border-radius);
  color: #fff;
  cursor: pointer;
  margin: 0 5px;
}

.admin-btn-edit {
  background-color: var(--primary-color);
}

.admin-btn-delete {
  background-color: #dc3545;
}

.admin-btn-edit:hover {
  background-color: var(--btn-hover-color);
}

.admin-btn-delete:hover {
  background-color: #c82333;
}

/* Pagination */
.admin-pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.pagination-btn,
.pagination-active {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.pagination-active {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: bold;
}
