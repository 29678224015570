.reset-pass-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: white;
}

.reset-left {
    width: 65%;
    background: url('../../Assets/Images/entrance.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
  }
  
  .reset-left::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(73, 73, 73, 0.274); /* Black color with 50% opacity */
    z-index: 1; /* Ensures it is above the background but below content */
  }

  .reset-right {
    width: 35%;
    height: 100%;
    background-color: #10105A;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

.form-box {
    width: 70%;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    background-color: white;
    /* box-shadow: rgba(255, 255, 255, 0.87) 0px 7px 29px 0px; */
    box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.9) 0px -3px 5px;
    flex-direction: column;
    border: 1px solid rgb(201, 201, 201);
    padding: 10px;
}

.form-box form {
    width: 100%;
}

.form-box span {
    margin-bottom: 10px;
}

.reset-logo {
    margin-bottom: 20px;
}

.reset-logo img {
    width: 50%;
    margin: 0 auto;
}

.input-box {
    width: 100%;
}

.reset-input {
    position: relative;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
}

.input-title {
    position: absolute;
    font-size: 1rem;
    color: rgb(34, 34, 136);
    padding: 0 10px;
    margin: 8px 25px;
    background-color: white;
    transition: 0.2s ease;
}

.reset-input input {
    position: absolute;
    width: 100%;
    outline: none;
    font-size: 0.9rem;
    padding: 5px 20px;
    line-height: 40px;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 211);
    background-color: transparent;
    transition: 0.1s ease;
    z-index: 1;
}

.reset-input input:focus,
.reset-input input:valid,
.reset-input input:active {
    color: rgb(109, 109, 109);
    border: 1px solid rgb(109, 109, 109);
}

.reset-input input:focus + .input-title,
.reset-input input:valid + .input-title,
.reset-input input:active + .input-title {
    color: rgb(109, 109, 109);
    height: 30px;
    line-height: 30px;
    transform: translate(-25px, -21px) scale(0.7);
    padding: 0 12px;
    z-index: 2;
}

.reset-btn {
    background-color: rgb(21, 21, 100);
    color: white;
    font-weight: 500;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 15px auto;
    display: block;
    width: fit-content;
    cursor: pointer;
}

.show-hide {
    position: absolute;
    right: 20px;
    top: 80%;
    transform: translateY(-80%) ;
    cursor: pointer;
    font-size: 0.9rem;
    color: rgb(34, 34, 136);
    z-index: 2222;
}
