/* Overlay for background dimming */
.organization-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure it's above other content */
    transition: opacity 0.3s ease; /* Smooth opacity transition */
}

.organization-employee-profile .MuiAvatar-root {
    width: 85px;
    height: 85px;
    object-fit: cover; /* Ensures the image fits within the circle */
    border-radius: 50%; /* Keeps the avatar circular */
}

/* Organization Wrapper */
.organization-main-wrap {
    display: flex;
    position: relative;
    transition: margin-right 0.5s ease-in-out; /* Smooth transition for margin */
}

/* Organization Details */
.organization-details {
    width: 100%; /* Full width by default */
    padding-top: 30px;
    transition: width 0.5s ease-in-out; /* Smooth transition for width */
}

/* Filters Section */
.organization-filters {
    display: flex;
    position: relative;
    right: 0;
    padding: 10px; /* Add padding for spacing */
    border-radius: 8px; /* Slight rounding for smooth look */
}

/* Add Employee Button */
.organization-add-employee {
    background-color: rgb(15, 15, 99); /* Dark blue */
    color: #ffffff; /* White text */
    padding: 10px 15px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.3s ease; /* Smooth background transition */
}

.organization-employee-profile{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.organization-add-employee:hover {
    background-color: #0a0a73; /* Darker blue on hover */
}

/* Profile Card Container */
.organization-profile-card-container {
    position: fixed;
    right: -300px; /* Start outside the viewport */
    width: 23%; /* Fixed width for the profile card */
    height: 100%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.5s ease-in-out; /* Smooth slide-in and slide-out */
}

/* Profile Card Visible State */
.organization-profile-card-container.show {
    right: 0;
    top: 80px; /* Slide into the viewport */
}

/* Adjust Organization Section When Profile Card is Visible */
.organization-details.expanded {
    margin-right: 300px; /* Leave space for the profile card */
}

/* Organization Grid */
.organization-grid-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive column layout */
    padding: 20px;
    transition: margin-right 0.5s ease-in-out; /* Smooth margin transition */
}

/* Responsive Layout for Smaller Screens */
@media (max-width: 768px) {
    .organization-grid-wrapper {
        grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
    }

    .organization-profile-card-container {
        width: 80%; /* Increase width for better visibility on small screens */
    }

    .organization-details.expanded {
        margin-right: 80%; /* Adjust margin to match the profile card width */
    }
}
