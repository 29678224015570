.login-wrap {
  width: 100%;
  height: 100vh; /* Full screen height */
  display: flex;
  background-color: white;
}

.login-left {
  width: 65%;
  background: url('../../Assets/Images/entrance.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
}

.login-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(73, 73, 73, 0.274); /* Black color with 50% opacity */
  z-index: 1; /* Ensures it is above the background but below content */
}

.login-right {
  width: 35%;
  height: 100%;
  background-color: #10105A;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-logo {
  margin-bottom: 30px;
}

.login-logo img {
  margin: auto;
  align-items: center;
  width: 50%;
}

.login-form {
  width: 70%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.9) 0px -3px 5px;
  padding: 20px;
}

.login-form input {
  margin-bottom: 20px;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  border: 1px solid #c0c0c0;
  background-color: rgb(248, 248, 248);
}

.login-form input:focus {
  border: 1px solid rgb(197, 197, 197);
  outline: none;
  background-color: white;
  color: black;
}

.login-form button {
  outline: none;
  background-color: rgb(36, 21, 100);
  color: rgb(255, 255, 255);
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: rgb(30, 18, 87);
}

.login-form .show-hide {
  position: absolute;
  right: 20px;
  top: 65%;
  transform: translateY(-80%);
  cursor: pointer;
  font-size: 0.9rem;
  color: rgb(34, 34, 136);
  z-index: 2222;
}

.logipass-input {
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.logiemail-input {
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.login-error {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
}

.login-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.popup h2 {
  margin-bottom: 10px;
  font-size: 20px;
}

.popup p {
  margin-bottom: 20px;
  color: #555;
}

.popup button {
  background: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup button:hover {
  background: #0056b3;
}
