/* Add this CSS to your stylesheet or within the same file using styled-components or other methods */

.edit-user {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="password"],
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .form-group p {
    font-size: 12px;
    color: #555;
  }
  
  .form-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-group button:first-child {
    background-color: #ee5252;
    margin-right: 10px;
  }
  
  .form-group button:last-child {
    background-color: #28a745;
    color: white;
  }
  