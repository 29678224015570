.reqreset-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: white;
  }
  
  .reqreset-left {
    width: 65%;
    background: url('../../Assets/Images/entrance.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
  }
  
  .reqreset-left::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(73, 73, 73, 0.274); /* Black color with 50% opacity */
    z-index: 1; /* Ensures it is above the background but below content */
  }
  
  .reqreset-right {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(16, 16, 90);
  }
  
  .reqreset-logo {
    margin-bottom: 30px;
  }
  
  .reqreset-logo img {
    margin: auto;
    align-items: center;
    width: 50%;
  }

  .info-text{
    margin-bottom: 10px;
  }
  
  .reqreset-form {
    width: 70%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    background-color: white;
    /* box-shadow: rgba(255, 255, 255, 0.87) 0px 7px 29px 0px; */
    box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.9) 0px -3px 5px;
    padding: 10px;
  }
  
  .reqreset-form input {
    margin-bottom: 20px;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #c0c0c0;
    background-color: rgb(248, 248, 248);
    color: #ccc;
  }
  
  .reqreset-form input:focus {
    border: 1px solid rgb(197, 197, 197);
    outline: none; /* Removes default browser outline */
    background-color: white;
    color: black;
  }
  
  .reqreset-form button{
    border: 1px solid rgb(197, 197, 197);
    outline: none; /* Removes default browser outline */
    background-color: rgb(36, 21, 100);
    color: rgb(255, 255, 255);
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
 