/* Basic styling for the 404 page */
.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
}

.not-found-content {
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.not-found-title {
    font-size: 80px;
    font-weight: bold;
    margin: 0;
}

.not-found-message {
    font-size: 18px;
    margin: 20px 0;
}

.home-link {
    text-decoration: none;
    font-size: 16px;
    color: #007bff;
    padding: 8px 16px;
    background-color: #f1f1f1;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.home-link:hover {
    background-color: #e2e6ea;
}
