@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofadi+One&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;



*{
  margin: 0;
  padding: 0;
}

body{
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

/* Disable text selection for the entire page */
  body {
    -webkit-user-select: none; /* For Safari and Chrome */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For IE/Edge */
    user-select: none; /* Standard syntax */
    overflow-x: scroll;
  }


