.add-service-form {
    padding: 20px;
  }
  
  .form-container {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .suggestions-dropdown {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    width: 100%; /* Match input width */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .comment-btn {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .comment-btn:hover {
    background-color: #0056b3;
  }
  
  .comment-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .comment-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .comment-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .button-box {
    display: flex;
    justify-content: space-between;
  }
  
  .submit-btn {
    background-color: #28a745;
    color: white;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: white;
    padding: 13px 0;
  }
  
  .cancel-btn:hover {
    background-color: #c82333;
  }
  
  .MuiInputLabel-root.MuiInputLabel-shrink {
    color: #3f51b5; /* Modern label color */
    background-color: white;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 8px; /* Rounded input fields */
  }
  
  .MuiOutlinedInput-root.Mui-focused {
    border-color: #3f51b5; 
  }

  