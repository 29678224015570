/* App.css */

/* Global Body Styles */
body {
  background-color: #f4f4f4; /* Light gray or choose a contrasting color */
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

/* App Layout */
.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Sticky Header */
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: rgb(8, 7, 83);
  color: white;
  font-weight: 300;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  transition: top 0.3s;
}

/* Main Content Wrapper */
.home-wrap {
  display: flex;
  flex: 1;
  width: 100%;
  transition: margin-left 0.3s, width 0.3s;
}

/* Sidebar Styles */
.home-wrap .left {
  width: 250px;
  top: 80px;
  left: 0;
  height: 100%;
  background-color: #fff;
  z-index: 300;
  transition: width 0.3s;
}

/* Collapsed Sidebar */
.home-wrap .left.collapsed {
  width: 80px;
}

/* Main Content Area */
.home-wrap .right {
  flex: 1;
height: 100%;
}


/* Full Width Adjustment */
.home-wrap .right.full-width {
  margin-left: 0;
}

/* Toggle Button (for Sidebar) */
.toggle-btn {
  display: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .home-wrap .left {
    transform: translateX(-100%);
  }

  .home-wrap .left.collapsed {
    transform: translateX(0);
  }

  .home-wrap .right {
    margin-left: 0;
  }
}
