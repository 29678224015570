/* Employee Database Wrapper */
.Employee-DB-wrap {
  padding: 3% 2%;
  background-color: #F6F5FB; /* Light blue-gray background for a subtle contrast */
}

/* Data Filter Tab */
.data-filter-tab {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.page-titel {
  width: 40%;
}

.page-titel h1 {
  font-size: 26px;
  line-height: 31px;
  font-weight: 500;
  color: #080250; /* Dark blue text color */
}

/* Employees Data Section */
.employees-data {
  padding-top: 3%;
}

/* Employee Grid */
.employee-grid {
  width: 95%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, calc(100% / 4));
  padding: 10px;
}

/* Filter Grid Item */
.filter-grid-item {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly translucent white */
  border: 1px solid rgba(224, 224, 224, 0.8);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  color: #080250; /* Dark blue text */
}

/* Employee Grid Item */
.employee-grid-item {
  background-color: #ffffff;
  border: 1px solid rgba(224, 224, 224, 0.8);
  border-radius: 5px;
  padding: 20px 10px;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for a card effect */
  color: #080250; /* Dark blue text */
}

/* Employee Image */
.employee-grid-item img {
  border-radius: 50%;
  width: 100%;
  object-fit: contain;
  margin: auto;
}

/* Employee Profile */
.employee-profile {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Employee Name */
.employee-name {
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #080250; /* Dark blue text */
}

/* Employee Designation */
.employee-designation {
  text-transform: capitalize;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(130, 130, 150); /* Slightly muted gray-blue */
}

/* Profile Settings Icon */
.profile-settings {
  width: 5%;
  margin-top: -15px;
  font-size: 20px;
  color: rgb(130, 130, 150); /* Muted gray-blue for icon */
  cursor: pointer;
}
